import {serverSideTranslations} from 'next-i18next/serverSideTranslations';

import NotFoundPage from '@/src/NotFoundPage/components/NotFoundPage';

const Error404Page = () => {
  return <NotFoundPage />;
};

export default Error404Page;

export const getStaticProps = async ({locale}) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
});
