import Link from 'next/link';

import Img404 from '@/public/images/404.svg';
import LogoSvg from '@/public/images/logo.svg';
import useRoutePaths from '@/src/hooks/useRoutePaths';
import TextLocal from '@/src/textLocal/containers/TextLocal';

import {ApplicationContainer, Button, Text} from '../../ui';
import css from '../styles/NotFoundPage.module.css';

const NotFoundPage = () => {
  const year = new Date().getFullYear();
  const {INDEX_ROUTE} = useRoutePaths();

  return (
    <main className={css.wrapper}>
      <ApplicationContainer>
        <div className={css.container}>
          <Link href={INDEX_ROUTE.getLink()}>
            <a className={css.logo} aria-label={'medyoucate logo'}>
              <LogoSvg />
            </a>
          </Link>
          <section className={css.content}>
            <div className={css.heading}>
              <Text
                tagComponent={Text.TAG.H1}
                color={Text.COLOR.PRIMARY}
                fontFamily={Text.FONT_FAMILY.RUBIK}
                fontWeight={Text.FONT_WEIGHT.MEDIUM}
                fontSize={Text.FONT_SIZE.SIZE_44}
                transform={Text.TRANSFORM.CAPITALIZE}
                lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_4}
              >
                <TextLocal id={'not-found.title'} />
              </Text>
            </div>
            <div className={css.description}>
              <Text
                fontFamily={Text.FONT_FAMILY.POPPINS}
                color={Text.COLOR.GREY}
                fontSize={Text.FONT_SIZE.SIZE_20}
                lineHeight={Text.LINE_HEIGHT.LINE_HEIGHT_1_6}
                tagComponent={Text.TAG.P}
              >
                <TextLocal id={'not-found.description'} />
              </Text>
            </div>
            <Link href={INDEX_ROUTE.getLink()}>
              <a>
                <Button height={Button.HEIGHT.REGULAR}>
                  <TextLocal id={'not-found.back'} />
                </Button>
              </a>
            </Link>
            <div className={css.image404}>
              <Img404 />
            </div>
          </section>
          <Text
            tagComponent={Text.TAG.P}
            fontSize={Text.FONT_SIZE.SIZE_14}
            color={Text.COLOR.GREY}
          >
            © {year} <TextLocal id={'general.company-name'} />
          </Text>
        </div>
      </ApplicationContainer>
    </main>
  );
};

export default NotFoundPage;
